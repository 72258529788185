import { defineStore } from 'pinia';

export const useCarsStore = defineStore('carsStore', {
  state: () => ({
    list: [],
    counter: null,
    minPrice: 0,
    loading: false,
    loadingButton: false,
    activeClassId: 0,
  }),
  actions: {
    async get(payload) {
      let params = new URLSearchParams(payload).toString();
      const { data: item } = await useFetch(`/api/v2/search_cars?${params}`);
      if (payload.is_clear) {
        this.list = [];
      }
      if (item.value) this.list.push(...item.value.vehicles);
      if (item.value) this.counter = item.value.vehiclesCount;
      if (item.value) this.minPrice = item.value.minPrice;
      if (window.innerWidth < 761) {
        const el = document.getElementById('cars');
        if (el) {
          el.scrollIntoView({ behavior: "smooth" });
        }
      }
      this.loading = false;
    },
    setClassId(id) {
      this.activeClassId = id
    },
    clearList() {
      this.list = [];
    },
  }
});