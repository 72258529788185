<script setup lang="ts">
	import { ref, defineProps } from 'vue';
	import { useLocationsStore } from '~/stores/LocationsStore';
	import { useCarsStore } from '~/stores/CarsStore';
	import {TIME_DATA} from '../layouts/timeData'
	const nuxtApp = useNuxtApp();
	const router = useRouter()
	const route = useRoute()
	const locationsStore = useLocationsStore();
	const cars = useCarsStore();

	const prop = defineProps({
		type: { default: 'desktop', type: [String] },
  	})
	const active = ref('');
	let activeOff = ref({name: 'Там же'});
	const isFocusedPickUp = ref(false);
	const isFocusedDropOff = ref(false);
	const timepicker1 = ref(false);
	const timepicker2 = ref(false);
	let pickUp = ref('');
	let dropOff = ref('');
	let calendar = ref(null);
	let calendarMob = ref(null);
	let promocode = ref(route.query['promocode'] || '')
	
	// Начало аренды по умолчанию
	let now = new Date();
	now.setDate(now.getDate() +  3);
	if (route.query['from-date']) {
		now = nuxtApp.$moment(route.query['from-date'], 'DD.MM.YYYY').toDate();
	}
	let dateStart = ref(now);
	const numWeeks = 2;
	// кол-во дней аренды
	let days = ref(7);
	// Конец аренды по умолчанию
	let now2 = new Date();
	now2.setDate(now2.getDate() +  10);
	if (route.query['to-date']) {
		now2 = nuxtApp.$moment(route.query['to-date'], 'DD.MM.YYYY').toDate();
	}
	let dateEnd = ref(now2);
	if (route.query['to-date']) {
		let duration = nuxtApp.$moment.duration(nuxtApp.$moment(dateEnd.value).diff(nuxtApp.$moment(dateStart.value)));
		days.value = duration.asDays();
	}
	let isShowCalendar = ref(false);
	let isShowCalendarMobile = ref(false);

	let isPromocode = ref(route.query.promocode ? true : false);

	let fromTime = ref(route.query['from-time'] ? route.query['from-time'] : '12:00');
	let toTime = ref(route.query['to-time'] ? route.query['to-time'] : '12:00');

	const setFromTime = (time: String) => {
		fromTime.value = time;
		timepicker1.value = false;
	}
	const setToTime = (time: String) => {
		toTime.value = time;
		timepicker2.value = false;
	}
	const showTime = (item: Number) => {
		if (item == 1) {
			timepicker1.value = true;
			timepicker2.value = false;
		} else if (item == 2) {
			timepicker1.value = false;
			timepicker2.value = true;
		}		
	}
	

	const setPoint = async (point: Object) => {
		active.value = point;
		isFocusedPickUp.value = false;
		pickUp.value = '';
		await locationsStore.getDropOffList(active.value.id);
		activeOff.value = locationsStore.dropOffList.find(point => point.id == active.value.id);
		activeOff.value.name = 'Там же';	
		console.log(activeOff.value)

		// search();	
	};

	const setPointDropOff = async (point: Object) => {
		activeOff.value = point;
		isFocusedDropOff.value = false;
		dropOff.value = '';
		// search();
	};
	
	const setActivePickupPoint = (id) => {
		return locationsStore.pickUpList.find(point => point.id == id) || locationsStore.pickUpList[0];
	};

	const setActiveDropOffPoint = (id) => {
		return locationsStore.dropOffList.find(point => point.id == id);
	};
	const setDefaultParams = async () => {
		const fromId = locationsStore.item?.searchParams['from-id'] || route.query['from-id'];
		const toId = route.query['to-id'] || locationsStore.item?.searchParams['to-id'];


		active.value = setActivePickupPoint(fromId);
		await locationsStore.getDropOffList(active.value.id);

		if (locationsStore.dropOffList) {
			activeOff.value = setActiveDropOffPoint(toId) || setActiveDropOffPoint(active.value.id);
			if (!toId) {
				activeOff.value.name = 'Там же';
			}
		}
	};

	const toggleDatepicker = () => {
		isShowCalendar.value = !isShowCalendar.value;
	};

	const toggleDatepickerMobile = () => {
		isShowCalendarMobile.value = !isShowCalendarMobile.value;
	};

	const toggleIsPromocode = () => {
		isPromocode.value = true;
		nextTick(() => {
			document.getElementById("promoInput").focus();
		})
	};

	const search = async () => {
		if (window && window.ym) {
      window.ym(45380325, 'reachGoal', 'button_findcar');
    };
		cars.loading = true;
		locationsStore.loading = true;

		const date_1 = nuxtApp.$moment(dateStart.value).format('DD.MM.YYYY');
		const date_2 = nuxtApp.$moment(dateEnd.value).format('DD.MM.YYYY');
		const time_1 = fromTime.value || '12:00';
		const time_2 = toTime.value || '12:00';
		const promo = promocode.value;
		
		let from, slug, to;

		if (route.params.slug && !route.query['from-id']) {
			slug = active.value.slug || route.params.slug;
			await locationsStore.getItem(slug);
			from = locationsStore.item.searchParams['from-id'];
			to = activeOff.value.id || from;
		} else if (active.value.id && active.value.slug) {
			from = active.value.id || route.query['from-id'];
			slug = active.value.slug;
			to = activeOff.value.id || route.query['to-id'] || from;
		}

		if (from && to) {
			const payload = {
				'from-id': from,
				'to-id': to,
				'to-date': date_2,
				'from-date': date_1,
				'from-time': time_1,
				'to-time': time_2,
				'promocode': promo,
				'is_clear': true,
				'per_page': route.name == 'city' ? 12 : 24,
			};
			console.log(payload)

			if (route.params.slug && !route.query['from-id']) {
				locationsStore.loading = false;
				cars.get(payload).then(() => {
					cars.loading = false;
				});
			} else if (active.value.id && active.value.slug) {
				router.push(`/${slug}?from-id=${from}&to-id=${to}&from-date=${date_1}&to-date=${date_2}&from-time=${time_1}&to-time=${time_2}&promocode=${promo}`);
				if (route.name == 'city') {
					await locationsStore.getItem(route.params.slug);
					locationsStore.loading = false;
					cars.get(payload).then(() => {
						setTimeout(() => {
							cars.loading = false;
						}, 1000);
					});
				}
			}
		}
	};

	setDefaultParams();

	const daysCounter = computed(() => {
	// Преобразование времени в минуты с момента полуночи
	const timeToMinutes = (time: string) => {
		const [hours, minutes] = time.split(':').map(Number);
		return hours * 60 + minutes;
	};

	// Расчет разницы во времени
	const startTimeInMinutes = timeToMinutes(fromTime.value);
	const endTimeInMinutes = timeToMinutes(toTime.value);
	const timeDifferenceInHours = (endTimeInMinutes - startTimeInMinutes) / 60;

	// Разница в датах
	const dateDifferenceInDays = nuxtApp.$moment(dateEnd.value).diff(nuxtApp.$moment(dateStart.value), 'days');

	// Применение условий
	if (dateDifferenceInDays === 0) {
		return 1;
	} else if (timeDifferenceInHours < 3) {
		return dateDifferenceInDays;
	} else {
		return dateDifferenceInDays + 1;
	}
	});

	days = daysCounter;

	onMounted(() => {
		// Самая ранняя даты аренды
		let minDate = new Date();
		// minDate.setDate(minDate.getDate() + 3);

		const dateStartSelect = new Date();
		dateStartSelect.setDate(dateStartSelect.getDate() + 3);


		// Инициализация календаря
		if (calendar.value === null && prop.type == 'desktop') {
			calendar.value = new nuxtApp.$datepicker('#calendar', {
				minDate: minDate,
				startDate: minDate,
				range: true,
				selectedDates: [dateStartSelect],
				onSelect(data) {
					if (data.date.length == 2) {
						isShowCalendar.value = false;
						let date_1 = data.date[0];
						let date_2 = data.date[1];
						dateStart.value = date_1;
						dateEnd.value = date_2;
						let duration = nuxtApp.$moment.duration(nuxtApp.$moment(date_2).diff(nuxtApp.$moment(date_1)));
						days.value = Math.round(duration.asDays());
					}
				},
			});
		}
		if (calendarMob.value === null && prop.type == 'mobile') {
			calendarMob.value = new nuxtApp.$datepicker('#calendar_mob', {
				minDate: minDate,
				range: true,
				onSelect(data) {
					if (data.date.length == 2) {
						isShowCalendarMobile.value = false;
						let date_1 = data.date[0];
						let date_2 = data.date[1];
						dateStart.value = date_1;
						dateEnd.value = date_2;
						let duration = nuxtApp.$moment.duration(nuxtApp.$moment(date_2).diff(nuxtApp.$moment(date_1)));
						days.value = Math.round(duration.asDays());
					}
				},
			});
		}
		//calendar.selectDate([nuxtApp.$moment(dateStart).format('YYYY-MM-DD'), nuxtApp.$moment(dateEnd).format('YYYY-MM-DD')]);
		document.addEventListener('click', function (event) {
			if (!event.target.classList.contains('order-form__autocomolete-item--pick-up') && !event.target.classList.contains('order-form__pick-up')) {
				isFocusedPickUp.value = false;
			};
			if (!event.target.classList.contains('order-form__autocomolete-item--drop-off') && !event.target.classList.contains('order-form__drop-off')) {
				isFocusedDropOff.value = false;
			};
			if (!event.target.classList.contains('order-form__period') && !event.target.closest('.calendar')) {
				isShowCalendar.value = false;
			};
			if (!event.target.classList.contains('order-form__autocomolete--time') && !event.target.classList.contains('order-form__control')) {
				if (timepicker2.value == true && timepicker1.value == false) {
					timepicker2.value = false;
				}
				if (timepicker1.value == true && timepicker2.value == false) {
					timepicker1.value = false;
				}
			};
		});
	});
</script>

<template>
	<div class="order-form__top">
		<nuxt-link to="/" class="order-form__logo"></nuxt-link>
		<div class="order-form__tabs">
			<nuxt-link to="/" class="order-form__tabs-item" :class="{active: route.name !== 'corporate'}" v-if="route.name == 'corporate'">
				<p>Физлица</p>
			</nuxt-link>
			<div class="order-form__tabs-item" :class="{active: route.name !== 'corporate'}" v-else>
				<p>Физлица</p>
			</div>
			<nuxt-link to="/corporate" class="order-form__tabs-item" :class="{active: route.name == 'corporate'}">
				<p>Юрлица</p>
			</nuxt-link>
		</div>
		<div class="order-form__fields">
			<div class="order-form__box">
				<div class="order-form__field with-line pin">
					<label class="form-label">Получение</label>
					<input type="text" :placeholder="active?.name ? active.name : 'Выберите город'" class="order-form__control order-form__pick-up" @focus="isFocusedPickUp = true" v-model="pickUp">
				</div>
				<div v-if="isFocusedPickUp" class="order-form__autocomolete">
					<div v-for="point in locationsStore.pickUpList.filter(p => p.name.toLowerCase().includes(pickUp.toLowerCase()))" @click="setPoint(point)" class="order-form__autocomolete-item order-form__autocomolete-item--pick-up">
						<font-awesome-icon :icon="['fas', 'building']" v-if="point.type == 'city'" />
						<font-awesome-icon :icon="['fas', 'subway']" v-if="point.type == 'railway_station'" />
						<font-awesome-icon :icon="['fas', 'plane']" v-if="point.type == 'airport'" />
						<font-awesome-icon :icon="['fas', 'location-dot']" v-if="point.type == null" />
						{{point.name}}
					</div>
				</div>
				<div class="order-form__field pin" v-if="activeOff">
					<label class="form-label">Возврат</label>
					<input type="text" :placeholder="activeOff.name" class="order-form__control order-form__drop-off" @focus="isFocusedDropOff = true" v-model="dropOff">
				</div>
				<div v-if="isFocusedDropOff && locationsStore.dropOffList && locationsStore.dropOffList.length > 0" class="order-form__autocomolete">
					<div v-for="point in locationsStore.dropOffList.filter(p => p.name.toLowerCase().includes(dropOff.toLowerCase()))" @click="setPointDropOff(point)" class="order-form__autocomolete-item order-form__autocomolete-item--drop-off">
						<font-awesome-icon :icon="['fas', 'building']" v-if="point.type == 'city'" />
						<font-awesome-icon :icon="['fas', 'subway']" v-if="point.type == 'railway_station'" />
						<font-awesome-icon :icon="['fas', 'plane']" v-if="point.type == 'airport'" />
						<font-awesome-icon :icon="['fas', 'location-dot']" v-if="point.type == null" />
						{{point.name}}
					</div>
				</div>
				
			</div>
			<div class="order-form__box">
				<div class="order-form__field">
					<label class="form-label">Даты аренды</label>
					<div class="order-form__period" @click="toggleDatepicker" v-if="prop.type == 'desktop'">
						{{$moment(dateStart).format('DD.MM.YYYY')}} - {{$moment(dateEnd).format('DD.MM.YYYY')}}
						<div class="order-form__period-amount">{{days}} {{ $getNumberLabels(days, ['день', 'дня', 'дней']) }}</div>
					</div>
					<div class="order-form__period" @click="toggleDatepickerMobile" v-if="prop.type == 'mobile'">
						{{$moment(dateStart).format('DD.MM.YYYY')}} - {{$moment(dateEnd).format('DD.MM.YYYY')}}
						<div class="order-form__period-amount">{{days}} {{ $getNumberLabels(days, ['день', 'дня', 'дней']) }}</div>
					</div>
				</div>
				<template v-if="prop.type == 'desktop'">
					<Transition name="slide-fade">
						<div id="calendar" v-show="isShowCalendar" class="calendar"></div>
					</Transition>
				</template>
				<template v-if="prop.type == 'mobile'">
					<Transition name="slide-fade">
						<div id="calendar_mob" v-show="isShowCalendarMobile" class="calendar"></div>
					</Transition>
				</template>
			</div>
			<div class="order-form__box order-form__box--date">
				<div class="order-form__field order-form__field--time">
					<label class="form-label">Время подачи</label>
					<input type="text" readonly v-model="fromTime" class="order-form__control" @click="showTime(1)">
					<div class="order-form__autocomolete order-form__autocomolete--time" v-if="timepicker1">
						<div v-for="time in TIME_DATA" :key="time.time" @click="setFromTime(time.time)" class="order-form__autocomolete-item">{{time.time}}</div>
					</div>
				</div>
				<div class="order-form__field order-form__field--time">
					<label class="form-label">Время возврата</label>
					<input type="text" readonly v-model="toTime" class="order-form__control" @click="showTime(2)">
					<div class="order-form__autocomolete order-form__autocomolete--time" v-if="timepicker2">
						<div v-for="time in TIME_DATA" :key="time.time" @click="setToTime(time.time)" class="order-form__autocomolete-item">{{time.time}}</div>
					</div>
				</div>
			</div>
			<div class="order-form__row">
				<button class="promocode-btn" v-if="!isPromocode" @click="toggleIsPromocode">
					у меня есть Промокод
				</button>
				<input type="text" placeholder="введите промокод" class="promocode-control" id="promoInput" v-model="promocode" v-else>
				<button class="button button-accent button-search" @click="search">Поиск</button>
			</div>
		</div>
		<div class="order-form__banner">
			<div class="order-form__banner-image">
			<img src="@/assets/img/present.png" class="order-form__banner-img"></img>
			</div>
			<p class="order-form__banner-text">Получите скидку на первую аренду с промокодом <span class="order-form__banner-text--bold">hello</span></p>
		</div>
	</div>
</template>

<style lang="scss">
	.order-form__logo {
		display: block;
	}
	.order-form__pick-up {
		cursor: pointer;
	}
	.order-form__drop-off {
		cursor: pointer;
	}
	.order-form__autocomolete {
		background-color: #F9F9F9;
		color: #000;
		position: absolute;
		width: calc(100% + 2px);
		margin-left: -1px;
		margin-top: -1px;
		font-size: 14px;
		height: 205px;
		overflow-y: auto;
		z-index: 100;
		border-radius: 0 0 8px 8px;
		border: 1px solid rgba(0, 0, 0, 0.05);
	}
	.order-form__autocomolete-item {
		padding: 10px 15px;
		cursor: pointer;
		font-size: 13px;
		line-height: 1.5;
		&:hover {
			background-color: rgba(0,0,0,0.05);
		}
	}
	.order-form__box:after {
		z-index: 100;
	}

	.order-form__autocomolete::-webkit-scrollbar {
		background-color: rgba(255, 109, 16, 0.1);
		width: 5px;
	}

	.order-form__autocomolete::-webkit-scrollbar-thumb {
		background-color: #FF6D10;
	}
	.slide-fade-enter-active {
		transition: all 0.3s ease-out;
	}

	.slide-fade-leave-active {
		transition: all 0.3s ease-in;
	}

	.slide-fade-enter-from,
	.slide-fade-leave-to {
		transform: translateY(20px);
		opacity: 0;
	}

	.promocode-control {
		display: block;
	}
</style>
