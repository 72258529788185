<script setup lang="ts">
	import { useSettingsStore } from '~/stores/SettingsStore';
	const settingsStore = useSettingsStore();
</script>

<template>
	<div>
		<div class="order-form selector desk-only" data-margin-top="20">
			<BookingFormTop type="desktop" />
			<div class="order-form__bottom">
				<div class="order-form__phone">
					<div class="order-form__text">Забронировать по телефону</div>
					<a :href="`tel:${settingsStore.phone}`" class="order-form__phone-link">{{ settingsStore.phone }}</a>
				</div>
				<div class="order-form__socials">
					<div class="order-form__text">Задать вопрос в мессенджерах</div>
					<div class="order-form__socials-content">
						<a href="https://t.me/mycarrental_support_bot" target="_blank" class="order-form__socials-item order-form__socials-item--telegram"></a>
						<a href="https://api.whatsapp.com/send/?phone=79788164589&text&type=phone_number&app_absent=0" target="_blank" class="order-form__socials-item order-form__socials-item--whatsapp"></a>
						<a v-if="settingsStore.links.viber_url" :href="`${settingsStore.links.viber_url}`" target="_blank" class="order-form__socials-item order-form__socials-item--viber"></a>
					</div>
				</div>
			</div>
		</div>
		<div class="order-form mobile-only">
			<BookingFormTop type="mobile" />
		</div>
	</div>
</template>